import React from 'react'
import Layout from '../components/layout-v2';

import {Link} from 'gatsby'

const Page = () => (

  <Layout title="Terms of Service">
    <section className="section-terms-of-service">

      <strong>DRAFT ATTORNEY CLIENT COMMUNICATION ATTORNEY WORK PRODUCT</strong><br/><br/>

      <b><u>Terms of Use</u></b><br/><br/>

      Your use of the BellMe website (the "Site") is governed by these terms of
      service, which BellMe may change or update from time to time in its sole
      discretion. <b>If you do not agree to these terms, please do not use this website.</b><br/><br/>

      <strong>COPYRIGHT</strong><br/><br/>

      All content included on this Site, such as text, graphics, logos, button icons,
      images, audio clips, and software, is the sole and exclusive property of BellMe
      or its suppliers and protected by U.S. and international copyright laws. The compilation
      (meaning the collection, arrangement, and assembly) of all content on this Site, and all
      software used on or accessible from this Site, are owned by BellMe or its software
      suppliers and protected by U.S. and international copyright laws. PLEASE NOTE that any
      contributions you provide to the site for posting or other display become the property
      of BellMe, and you agree to assign to BellMe all your right title and interest
      in those submissions.<br/><br/>

      <strong>TRADEMARKS</strong><br/><br/>

      "BellMe" and other BellMe graphics, logos, and service names are trademarks
      of BellMe.<br/><br/>

      <strong>DISCLAIMER</strong><br/><br/>

      THIS SITE IS PROVIDED TO YOU BY BellMe ON AN "AS IS" BASIS. BellMe MAKES NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
      THE SITE OR THE INFORMATION, CONTENT, MATERIALS, OR SERVICES INCLUDED ON THIS SITE.
      BellMe DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
      TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. <br/><br/>

      YOU UNDERSTAND AND EXPRESSLY AGREE THAT USE OF THE SITE IS AT YOUR SOLE RISK, THAT
      ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
      SITE IS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR
      ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
      SUCH MATERIAL AND/OR DATA.<br/><br/>

      BellMe WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
      CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND RESULTING FROM THE USE OF OR THE
      INABILITY TO USE THE SITE RESULTING FROM ANY GOODS OR SERVICES PURCHASED OR OBTAINED
      OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH THE SITE, RESULTING FROM LOSS
      OF, UNAUTHORIZED ACCESS TO OR ALTERATION OF A USER'S TRANSMISSIONS OR DATA OR FOR THE COST
      OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR
      LOSS OF PROFITS, USE, DATA OR OTHER INTANGIBLES, EVEN IF BellMe HAD BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES.<br/><br/>

      <b>APPLICABLE LAW</b><br/><br/>

      This site is created and controlled by BellMe in the State of Illinois USA. The laws
      of the State of Illinois govern these disclaimers, terms, and conditions, without giving
      effect to any principles of conflicts of laws. We reserve the right to make changes to
      our site and these disclaimers, terms, and conditions at any time. <br/><br/>

      <b>PRIVACY</b><br/><br/>

      You are responsible for maintaining the confidentiality of your account number and/or
      password. You are responsible for all uses of your account, whether or not actually or
      expressly authorized by you. You may access BellMe's Privacy Policy by clicking
      <Link to="/privacy-policy">here.</Link><br/><br/>

      <strong>OTHER TERMS</strong><br/><br/>

      We may modify or discontinue our services or your account with us, with or without
      notice, without liability to you, any other user or any third party. We reserve the
      right to terminate your use of this Site if we learn that you have provided us with
      false or misleading registration information, interfered with other users or the
      administration of BellMe's services, or violated these Terms of Service. <br/><br/>

      <strong>COMPLETE AGREEMENT </strong><br/><br/>

      These Terms of Service constitute the entire agreement between the parties with respect
      to the subject matter hereof and supersedes and replaces all prior or contemporaneous
      understandings or agreements, written or oral, regarding such subject matter. Any
      waiver of any provision of the Terms of Service will be effective only if in writing
      and signed by BellMe.<br/><br/>

      <b>YOUR AGREEMENT</b><br/><br/>

      Your use of this Site is subject to these terms of use. If you do not agree to these
      terms of use, please leave this Site.<br/><br/>

    </section>
  </Layout>
);

export default Page;

